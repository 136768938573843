*,
*::after,
*::before {
  box-sizing: border-box;
}

img {
  display: inline-block;
  max-width: 100%;
}

body {
  font-family: 'Lato', sans-serif;
  font-weight: 400;
  font-size: 16px;
  color: #22252a;
  background-color: #f4f8fc;
}

.homepage-slider {
  max-width: 350px;
}

.homepage-slider .control-dots {
  bottom: -90px !important;
}

.carousel.carousel-slider {
  overflow: unset !important;
}

.homepage-slider .control-dots {
  display: flex !important;
  align-items: center;
  justify-content: center;
}

.homepage-slider .control-dots li {
  border: 1px solid #00cfc8;
  width: 16px !important;
  height: 16px !important;
  box-shadow: none !important;
}

.homepage-slider .control-dots li.selected {
  width: 24px !important;
  height: 24px !important;
  background-color: #00cfc8 !important;
}

#customgpt_chat {
  padding: 0px 388px 0px;
  height: 700px;
}

.react-cookie-law-dialog {
  bottom: 0;
  top: unset !important;
}

.react-cookie-law-accept-selection-btn {
  border: 1px solid #00cfc8 !important;
  color: #00cfc8 !important;
  padding: 6px 20px !important;
  font-family: Lato !important;
  background-color: rgba(0, 0, 0, 0) !important;
  border-radius: 16px !important;
  -webkit-tap-highlight-color: transparent;
}
.react-cookie-law-accept-all-btn {
  font-family: Lato !important;
  font-size: 14px !important;
  font-weight: 500 !important;
  line-height: 1.2 !important;
  color: #ffffff !important;
  border: 1px solid #00cfc8 !important;
  background-color: #00cfc8 !important;
  border-radius: 16px !important;
  padding: 6px 20px !important;
}

.react-cookie-law-dialog  {
  background-color: #393E46 !important;
}
.react-cookie-law-policy {
  color: #00cfc8 !important;
}
.react-cookie-law-msg {
    color: #ffffff !important;
    font-family: Lato !important;
}
.react-cookie-law-select-pane > label {
    color: #ffffff !important;
}